<template>
  <div class="scroll-container">
    <el-table
        :data="enterprise_list"
        ref="table"
        border
        show-header
        highlight-current-row
        size="mini"
        @sort-change='sortthiscolumn'
        :default-sort = "{prop: 'enable', order: 'descending'}"
    >
      <el-table-column  align="center" width="50" label="">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.check" @change="setSelect_all"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column  align="center"  label="操作" width="200">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="bottom-start">
            <el-button size="mini"  plain @click="edit(scope.row)">
              <img :src="require('@/icons/svg/edit.svg')" >
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="设备列表" placement="bottom-start">
            <el-button size="mini" plain @click="equipment_list(scope.row)" :disabled="!scope.row.enable">
              <img :src="scope.row.enable?require('@/icons/svg/equipment-list.svg'):require('@/icons/svg/equipment-list-disabled.svg')" >
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="人员列表" placement="bottom-start">
            <el-button size="mini"  plain @click="personnel_list(scope.row)" :disabled="!scope.row.enable">
              <img :src="scope.row.enable?require('@/icons/svg/users.svg'):require('@/icons/svg/users-disabled.svg')" >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>


      <el-table-column align="center"  label="名称" sortable='custom' prop="name">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.name }}</span>
          <img v-if="row.low_power_number > 0" :src="require('@/icons/svg/low-power.svg')" style="margin-left:10px;" >
        </template>
      </el-table-column>

      <el-table-column align="center"  label="状态">
        <template slot-scope="{row}">
            <img v-for="item in row.typeList" :key="item" :src="item" style="margin-left: 10px;margin-right: 10px">
        </template>
      </el-table-column>

      <el-table-column class-name="status-col" align="center" label="区域"  sortable='custom' prop="area_id1">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ transformation(row.area_id1) }}</span>
        </template>
      </el-table-column>

      <el-table-column class-name="status-col" align="center" label="服务商"  sortable='custom' prop="supplier_name">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.supplier_name }}</span>
        </template>
      </el-table-column>

      <el-table-column  align="center" label="设备数量" sortable='custom' prop="number">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.number }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="授权有效期" custom>
        <template slot-scope="scope">
          <span v-if="2 == scope.row.termValidityColer" style="color: #5DC64F">{{ scope.row.lisense_expire_data }}</span>
          <span v-if="1 == scope.row.termValidityColer" style="color: #ffc43d">{{ scope.row.lisense_expire_data }}</span>
          <span v-if="0 == scope.row.termValidityColer" style="color: #ff0000">{{ scope.row.lisense_expire_data }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="联系人" >
        <template slot-scope="scope">
          <span :style="scope.row.enable?'':'color: #d0d0d0'">{{ scope.row.first_contact }}</span>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    enterprise_list: Array,
    edit: Function,
    equipment_list: Function,
    setSelect_all: Function,
    personnel_list: Function,
    sortthiscolumn: Function,
    transformation: Function,
  },
  data(){
    return {
      checkedList: []
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.scroll-container {
  height: calc(100vh - 55px - 50px - 50px);
  overflow: auto;
}
</style>