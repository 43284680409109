import request from '@/utils/request'
import qs from'qs'

export function getList(params) {
    return request({
        url: '/api/equipment/getList',
        method: 'post',
        params: params
    })
}

export function getStorageList(params) {
    return request({
        url: '/api/equipment/getStorageList',
        method: 'post',
        params: params
    })
}

export function add(params) {
    return request({
        url: '/api/equipment/add',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function update(params) {
    return request({
        url: '/api/equipment/update',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function getOneById(params) {
    return request({
        url: '/api/equipment/getOneById',
        method: 'post',
        params: params
    })
}
export function enableFalse(params) {
    return request({
        url: '/api/equipment/enableFalse',
        method: 'post',
        params: params
    })
}
export function enableTrue(params) {
    return request({
        url: '/api/equipment/enableTrue',
        method: 'post',
        params: params
    })
}

/**
 * @param {*} ids 
 * @description 导出设备列表
 * @author xiaoying
 * @date 2021-11-29 
 */
export function exportForm(params) {
    return request({
        url: '/api/equipment/exportForm',
        method: 'post',
        data: qs.stringify(params)
    })
}

/**
 * 
 * @param {*} params 
 * @description 迁移设备
 * @author xiaoying
 * @date 2022-12-15 
 */
export function removeEquipment(params) {
    return request({
        url: '/api/equipment/removeEquipment',
        method: 'post',
        params: params
    })
}