<template>
  <div>
    <supplier :provider_list="providerList" :cancel_form="cancelForm" :show_supplier="showSupplier" :enterprise_list="enterpriseList" ></supplier>
    <infor :region_list="regionList" :provider_list="providerList" :bus_id="busId" :form_data="formData" :show_edit="showEdit" :is_add="isAdd" :cancel_form="cancelForm"  ></infor>
    <equipment_index :ret_index="retIndex" :bus_id="busId" :show_equipment="showEquipment" :enterprise_id="enterpriseId"></equipment_index>
    <personnel_index :ret_index="retIndex" :bus_id="busId" :show_personnel="showPersonnel" :enterprise_id="enterpriseId"></personnel_index>
    <div style="height: 50px;padding: 10px;">
      <div style="float: left;width: 40%;margin-left: 1%;margin-top: 8px;color: #808080">
        <span>终端用户（{{ enterpriseList.length }}条/共{{ count }}条）</span>
      </div>
      <div class="tools-bar" style="float: right;padding-right: 20px;">
        <el-select v-model="areaId1" placeholder="区域" size="mini" style="width:11vw; padding-right: 0.7vw" clearable @change="selectAreaChange">
          <el-option
              v-for="item in regions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select v-model="supplierId" placeholder="服务商" size="mini" style="width:11vw; padding-right: 0.7vw" clearable @change="search">
          <el-option
              v-for="item in providers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input style="width:11vw; padding-right: 0.7vw;" size="mini" type="text" v-model="name" clearable placeholder="搜索" @keyup.enter.native="search" @clear="search"></el-input>
        <el-button style="margin-left: 0.7vw;" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </div>
    </div>
    <div style="clear: both;width: 100%;height: calc(100vh - 55px - 50px - 50px);margin-left: 1%;width: 98%;">
      <list :transformation="transformation" :sortthiscolumn="sortthiscolumn" :personnel_list="personnelList" :equipment_list="equipmentList" :enterprise_list="enterpriseList" :setSelect_all="setSelectAll" :edit="edit"></list>
    </div>
    <div class="bottom">
      <div style="float: left;margin-left: 35px;margin-top: 1.2vh;padding-right: 35px;margin-right: 35px;border-right: 1px solid #c5c5c5;">
        <el-checkbox v-model="selectAll" style="color: #808080;" @change="checkAll()">全选本页</el-checkbox>
        <!-- <el-button plain style="margin-left: 20px;color: #808080;" @click="reversal">选中其他</el-button> -->
        <el-button plain size="small" style="margin-left: 20px;color: #808080;" @click="transferSupplier">转至其他服务商</el-button>
      </div>
      <div style="float: left;margin-top: 1.2vh;">
        <el-button size="small" type="primary" @click="insert">新增</el-button>
      </div>
      <div style="float: right;margin-right: 1.2vw;margin-top: 1.2vh;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
import { getList} from '@/api/orgin'
import { selectAllSupplier,getProviders} from '@/api/supplier'
import { getRegion,getUsedRegion} from '@/api/area'
import list from './list'
import infor from './infor'
import equipment_index from "./equipment/equipmentIndex";
import personnel_index from "./personnel/personnelIndex"
import Supplier from "./supplier";

export default {
  components: {
    Supplier,
    list,
    infor,
    equipment_index,
    personnel_index,
  },
  data() {
    return {
      providers:[],
      name:null,
      column:{prop: 'enable', order: 'descending'},
      showSupplier:false,
      showPersonnel:false,
      enterpriseId:0,
      showEquipment: false,
      busId: 0,
      isAdd: false,
      showEdit: false,
      formData:{

      },
      selectAll: false,
      pageNo: 1,
      pageSize: 10,
      count: 365,
      supplierId: null,
      providerList: [

      ],
      regions:[],
      regionList: [],
      areaId1: null,
      enterpriseList:[],
      typeIcon:[
        require('@/icons/svg/water-drop.svg'),//水滴
        require('@/icons/svg/thermometer.svg'),//温度计
        require('@/icons/svg/fire-extinguisher.svg'),//灭火器
        require('@/icons/svg/low-power.svg')//电量低
      ],
    }
  },
  mounted() {
    this.getProviders()
    this.getRegions()
    var href = window.location.href;
    if(href.indexOf("template_id=b0758U9UgK4gEJNQbUPzBnuxihnNKHF_iCjck_47Ol4") != -1){
      let action;
      if(href.indexOf("action=confirm") != -1) {
        action = 'confirm'
      }else {
        action = 'cancel'
      }
      this.$router.push({path:'/redirect',query: {action:action}})
    }else {
      selectAllSupplier().then(res => {
        this.providerList = res.data.data
      })
      getRegion({pid:0}).then(res => {
        this.regionList = res.data.data
      })
      this.search()
    }
  },
  methods:{
    selectAreaChange(){
      this.supplierId=null;
      // this.getProviders()
      this.search();
    },
    transferSupplier(){
      let ids = []
      for (let i = 0; i < this.enterpriseList.length; i++) {
        if(this.enterpriseList[i].check){
          ids.push(this.enterpriseList[i].id)
        }
      }
      if(ids.length>0){
        this.showSupplier=true;
      }else{
        this.$message.error('请先选中终端用户！')
      }
    },
    //获取区域下拉框
    transformation(areaId){
      for(let i = 0;i < this.regionList.length; i++){
        if(this.regionList[i].id==areaId){
          return this.regionList[i].name
        }
      }

    },
    //排序方式
    sortthiscolumn (column){
      this.column=column
      this.search();
    },
    getProviders(){
      getProviders({areaId:this.areaId1}).then(res => {
        this.providers = res.data.data
      })
    },
    getRegions(){
      getUsedRegion().then(res => {
        this.regions = res.data.data
      })
    },
    //返回
    retIndex(){
      this.showPersonnel = false
      this.showEquipment = false
      this.search()
    },
    //翻页
    handleCurrentChange(pageNo){
      this.pageNo = pageNo
      this.init()
    },
    //设置列表显示行数
    handleSizeChange(size){
      this.setPageSizeIndex(size)
      this.search()
    },
    //打开人员窗口
    personnelList(row){
      this.showPersonnel=true
      this.busId = row.id;
      this.enterpriseId = row.id
    },
    //打开设备列表
    equipmentList(row){
      this.showEquipment=true
      this.busId = row.id;
      this.enterpriseId = row.id
    },
    //编辑窗口
    edit(row){
      this.busId = row.id;
      this.isAdd = false;
      this.showEdit = true;
    },
    //返回
    cancelForm(){
      this.showSupplier = false;
      this.showEdit = false;
      this.search()
    },
    //打开新增页面
    insert(){
      this.showEdit = true;
      this.isAdd = true;
    },
    //反选
    reversal(){
      for(let i = 0; i < this.enterpriseList.length ; i++){
        this.enterpriseList[i].check = !this.enterpriseList[i].check
      }
      this.setSelectAll()
    },
    setSelectAll(){
      for(let i = 0; i < this.enterpriseList.length ; i++){
        if(this.enterpriseList[i].check){

        }else{
          this.selectAll = false;
          return;
        }
      }
      this.selectAll = true;
    },
    //全选
    checkAll(){
      this.enterpriseList.forEach((row, index) => {
        row.check = this.selectAll
      })
    },
    //初始化列表
    search(){
      this.pageNo = 1
      this.init()
    },
    //查询列表
    init(){
      this.getProviders()
      this.getRegions()
      this.pageSize=this.pageSizeIndex
      var list
      getList({
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        name:this.name,
        supplierId:this.supplierId,
        areaId1:this.areaId1,
        prop:this.column.prop,
        order:this.column.order,
      }).then(res => {
        list = res.data.data.list
        var date = new Date();
        date.setMilliseconds(0)
        date.setSeconds(0)
        date.setMinutes(0)
        date.setHours(0)
        list.forEach((row, index) => {
          row.check = false
          if(row.type == null){
            row.type = ""
          }
          var split = row.type.split(",");
          row.typeList = []
          split.forEach((item, index) => {
            row.typeList.push(this.typeIcon[item]);
          })
          row.index = index
          var lisenseExpireData = new Date(row.lisense_expire_data);
          lisenseExpireData.setMilliseconds(0)
          lisenseExpireData.setSeconds(0)
          lisenseExpireData.setMinutes(0)
          lisenseExpireData.setHours(0)
          var day = ((lisenseExpireData-date)/(1000*3600*24));
          row.termValidityColer = day>30 ? 2 : (day >= 0 ? 1 : 0)
        })
        this.count=res.data.data.totalRow
        this.enterpriseList = list
        this.selectAll = false
      })
    }
  }
}
</script>

<style scoped>
.bottom{
  position: absolute;
  width: 98%;
  height: 50px;
  bottom: 0;
  margin-left: 1%;
  box-shadow: 0px -3px 3px #c4c4c4;
}
</style>