<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_edit" :before-close="cancel_form">
        <div style="position: absolute;width: 100%;top: 88vh">
          <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 30%"  @click="cancel_form()">取消</el-button>
          <el-button  class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">保存</el-button>
        </div>
        <div style="padding-top:2.2vh;padding-left: 2.4vw;padding-right: 2.2vh;height:88vh;overflow: auto;">
        <div v-if="is_add" slot="header" style="height: 50px" >
          <span><i class="el-icon-circle-plus-outline"></i> 添加</span>
        </div>
        <div v-else slot="header" style="height: 50px" >
          <span><i class="el-icon-edit"></i> 编辑</span>
        </div>



        <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
          <el-form-item label="库房:" prop="library">
            <el-input placeholder="请输入库房" type="text" v-model="formData.library" ></el-input>
          </el-form-item>

          <el-form-item label="设备名称:" prop="name">
            <el-input placeholder="请输入设备名称" type="text" v-model="formData.name" ></el-input>
          </el-form-item>

          <el-form-item label="设备类型:" prop="equipment_model_id" >
            <el-select :disabled="!is_add" v-model="formData.equipment_model_id" placeholder="设备类型" size="mini" style="width:200px; padding-right: 10px" clearable >
              <el-option
                  v-for="item in equModelList"
                  :key="item.id"
                  :label="item.model"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item v-if="formData.equipment_model_id == 3" label="温度阈值:" required>
            <el-col :span="7">
              <el-form-item prop="temperature_low">
                <el-input placeholder="请输入温度下限" max="80" min="-39" type="number" v-model="formData.temperature_low" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="text-align: center">—</el-col>
            <el-col :span="7">
              <el-form-item prop="temperature_high">
                <el-input placeholder="请输入温度上限" max="79" min="-40" type="number" v-model="formData.temperature_high" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="text-align: center">℃</el-col>
          </el-form-item>

          <el-form-item v-if="formData.equipment_model_id == 3" label="湿度阈值:" required>

            <el-col :span="7">
              <el-form-item prop="humidity_low">
                <el-select v-model="formData.humidity_low" placeholder="湿度阈值-低" size="mini"  clearable>
                  <el-option
                      v-for="item in humidity_low_list"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="text-align: center">—</el-col>
            <el-col :span="7">
              <el-form-item prop="humidity_high">
                <el-select v-model="formData.humidity_high" placeholder="湿度阈值-低" size="mini"  clearable>
                  <el-option
                      v-for="item in humidity_high_list"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="text-align: center">%</el-col>
          </el-form-item>
          <el-form-item label="设备IMEI:" prop="imei" >
            <el-input placeholder="请输入IMEI" :disabled="!is_add" type="text" v-model="formData.imei" ></el-input>
          </el-form-item>
          <el-form-item label="设备IMSI:" prop="imsi">
            <el-input placeholder="请输入IMSI" type="text" v-model="formData.imsi" ></el-input>
          </el-form-item>
          <el-form-item label="经度:" prop="longitude">
            <el-input placeholder="请输入经度" type="text" v-model="formData.longitude" ></el-input>
          </el-form-item>
          <el-form-item label="纬度:" prop="latitude">
            <el-input placeholder="请输入纬度" type="text" v-model="formData.latitude" ></el-input>
          </el-form-item>
          <el-form-item label="设备电量:" >
            <span>{{ formData.power/1000.0 }}V </span>
          </el-form-item>
          <el-form-item label="信号强度:">
            <span> {{formData.signal}}dBm </span>
          </el-form-item>
          <el-form-item label="启用停用:" prop="enable" >
              <el-checkbox class="myRedCheckBox" v-model="formData.enable"  :label="(formData.enable?'已启用':'已禁用')"></el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      </el-drawer>
    </transition>

  </div>


</template>

<script>
import {add,update,getOneById} from '@/api/equipment'
import {getAll} from '@/api/equipment_model'
import { isMobile } from '@/utils/common'
export default {
  props:{
    equ_id: Number,
    is_add: Boolean,
    cancel_form: Function,
    show_edit: Boolean,
    bus_id: Number,
    storage_list: Array,
  },
  watch:{
    show_edit(){
      if(this.show_edit){
        if(this.is_add){
          this.formData.imsi=null;
          this.formData.power=null
          this.formData.signal=null
          this.formData.enable=false
          this.formData.longitude = null
          this.formData.latitude = null
          this.$refs.ruleForm.resetFields();
        }else{
          getOneById({id:this.equ_id}).then(res => {
            this.$refs.ruleForm.resetFields();
            this.formData.equipment_model_id=res.data.data.equipment_model_id
            this.formData.library=res.data.data.library
            this.formData.name=res.data.data.name
            this.formData.temperature_high=res.data.data.temperature_high
            this.formData.temperature_low=res.data.data.temperature_low
            this.formData.humidity_low=res.data.data.humidity_low
            this.formData.humidity_high=res.data.data.humidity_high
            this.formData.imei=res.data.data.imei
            this.formData.imsi=res.data.data.imsi
            this.formData.power=res.data.data.power
            this.formData.signal=res.data.data.signal
            this.formData.enable=res.data.data.enable
            this.formData.longitude=res.data.data.longitude
            this.formData.latitude=res.data.data.latitude
          })

        }
      }
    }
  },
  data() {
    var imeiPass = (rule, value, callback) => {
      if(value){
        value+=""
      }
      var reg = /^\d{15}$/
      if (value === null || value.trim() === '') {
        return callback(new Error('设备IMEI号必须是 15 位数字'))
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('设备IMEI号必须是 15 位数字'))
        }
      }
    }
    var imsiPass = (rule, value, callback) => {
      if(value){
        value+=""
      }
      var reg = /^\d{1,15}$/
      if (value === null || value.trim() === '') {
        callback()
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('设备IMSI号必须是1-15位数字或不填'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if(value){
        value+=""
      }
      if (value === null || value.trim() === '') {
        callback(new Error('请输入正确坐标'))
      } else {
        let a = value.replace(/^\D*(\d*(?:\.\d{0,6})?).*$/g, '$1')
        if (a == value) {
          callback()
        } else {
          return callback(new Error('请输入正确坐标'))
        }
      }
    }
    var temperatureHighPass = (rule, value, callback) =>{
      if(value!=null && value != undefined){
        if(value>79){
          callback(new Error('温度上限不能高于79℃'));
        }
        if(value<-40){
          callback(new Error('温度上限不能低于于-40℃'));
        }
        callback();
      }else{
        callback(new Error('请选输入温度上限'));
      }
    }
    var temperatureLowPass = (rule, value, callback) =>{
      if(value!=null && value != undefined){
        if(value>80){
          callback(new Error('温度下限不能高于80℃'));
        }
        if(value<-39){
          callback(new Error('温度下限不能低于于-39℃'));
        }
        callback();
      }else{
        callback(new Error('请输入温度下限'));
      }
    }
    let humidity_low_list = []
    let humidity_high_list = []
    for (let i = 5,j = 10; i <= 90; i+=5,j+=5) {
      humidity_low_list.push(i)
      humidity_high_list.push(j)
    }
    return {
      humidity_high_list:humidity_high_list,
      humidity_low_list:humidity_low_list,
      equModelList:[],
      textColor:'#5DC64F',
      formData: {
        equipment_model_id:null,
        longitude:null,
        latitude:null,
        library:'',
        name:'',//设备名称
        temperature_high:0,
        temperature_low:0,
        humidity_low:0,//下限 湿度
        humidity_high:0,//上限 湿度
        imei:'',
        imsi:'',
        power:'',
        signal:'',
        enable:false,
      },
      rules:{
        equipment_model_id:[
          { required: true, message:'请选择设备类型', trigger: 'change' }
        ],
        longitude:[
          {required: true, validator: validatePass, trigger: 'blur' }
        ],
        latitude:[
          {required: true, validator: validatePass, trigger: 'blur' }
        ],
        name:[
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        library:[
          { required: true, message:'请选择库房', trigger: 'change' }
        ],
        temperature_low:[
          {required: true, validator: temperatureLowPass, trigger: 'blur' }
        ],
        temperature_high:[
          {required: true, validator: temperatureHighPass, trigger: 'blur' }
        ],
        humidity_low:[
          { required: true, message:'请选择湿度下限', trigger: 'change' }
        ],
        humidity_high:[
          { required: true, message:'请选择湿度上限', trigger: 'change'}
        ],
        imei:[
          {required: true, validator: imeiPass, trigger: 'blur' }
        ],
        imsi:[
          { validator: imsiPass, trigger: 'blur' }
        ]
      },
      drawerWidth:'35%'
    }
  },
  mounted() {
    getAll().then(res => {
      this.equModelList = res.data.data
    })
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '35%'
    }
  },
  methods: {
    //提交
    submitForm(){this.$refs.ruleForm.validate((valid) => {
      if (valid) {
        this.formData.orginId = this.bus_id
        if(this.is_add){
          add(this.formData).then(res => {
            if(res.data && res.data.state=="ok"){
              this.$message.success(res.data.msg ? res.data.msg :'保存成功')
              this.cancel_form();
            }else{
              this.$message.error(res.data.msg ? res.data.msg :'保存失败')
            }
          })
        }else {
          this.formData.id = this.equ_id
          update(this.formData).then(res => {
            if(res.data && res.data.state=="ok"){
              this.$message.success(res.data.msg ? res.data.msg :'保存成功')
              this.cancel_form();
            }else{
              this.$message.error(res.data.msg ? res.data.msg :'保存失败')
            }
          })
        }
      }
    })
    },
  },
}
</script>

<style scoped>
</style>
<style>
.myRedCheckBox .el-checkbox__input+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0f9f00;
}

.myRedCheckBox .el-checkbox__input .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #0f9f00;
}
</style>