import request from '@/utils/request'
import qs from 'qs'

export function getList(params) {
  return request({
    url: '/api/orgin/getList',
    method: 'post',
    params: params
  })
}

export function add(params) {
  return request({
    url: '/api/orgin/add',
    method: 'post',
    data: qs.stringify(params)
  })
}

export function update(params) {
  return request({
    url: '/api/orgin/update',
    method: 'post',
    data: qs.stringify(params)
  })
}

export function getOneById(params) {
  return request({
    url: '/api/orgin/getOneById',
    method: 'post',
    params: params
  })
}

export function replace(params) {
  return request({
    url: '/api/orgin/replace',
    method: 'post',
    params: params
  })
}

export function getSupById(params) {
  return request({
    url: '/api/supplier/getOneById',
    method: 'post',
    params: params
  })
}
