<template>
  <div>

    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_personnel" :before-close="ret_index">
        <div style="padding:10px">
        <div>
          <div style="float: left;width: 40%;margin-left: 1%;margin-top: 1vh;color: #808080">
            <span>人员列表（{{ personnel.length }}条/共{{ count }}条）</span>
          </div>
          <div class="tools-bar" style="float: right;padding: 0.4vw 0.8vh;height: 2.5vh;">
            <el-input style="width:11vw; padding-right: 0.7vw" size="mini" type="text" v-model="name" clearable placeholder="搜索" @keyup.enter.native="search" @clear="search"></el-input>
            <el-button style="" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          </div>
        </div>
        <div style="margin-top: 6.2vh;clear: both;margin-left: 1%;width: 98%;height: 86.5vh;">
          <personnelList :sortthiscolumn="sortthiscolumn" :edit="edit" :personnel="personnel" :set_select_all="setSelectAll"></personnelList>
        </div>
        <div class="bottom">
          <div class="tools-box">
            <el-checkbox v-model="selectAll" size="small" @change="checkAll()">全选本页</el-checkbox>
            <!-- <el-button plain style="margin-left: 1.2vw;width: 5.3vw;height: 3.8vh;color: #808080;" @click="reversal">选中其他</el-button> -->
            <el-popover style="margin-left: 0.8vw;margin-right: 0.5vw;"
                placement="top"
                width="190"
                v-model="showStop">
              <p>确定停用当前勾选的{{ quantityCheck() }}个人员？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" type="text" @click="showStop = false">取消</el-button>
                <el-button type="primary" size="small" @click="enableFalse">确定</el-button>
              </div>
              <el-button slot="reference" size="small">停用</el-button>
            </el-popover>
          </div>
          <div style="float: left;margin-top: 1.3vh;height: 3.8vh;margin-right: 0.5vw;margin-left: 0.5vw;">
            <el-button size="small" type="primary" @click="insert">新增</el-button>
          </div>
          <div style="float: left;margin-top: 1.3vh;height: 3.8vh;">
            <el-button size="small" class="cancel"                 @click="ret_index()">返回</el-button>
          </div>

          <div style="float: right;margin-right: 1vw;margin-top: 1.3vh;">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="4"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="count">
            </el-pagination>
          </div>
        </div>
      </div>
      </el-drawer>
    </transition>
    <personnelInfor :bus_id="bus_id" :per_id="perId" :show_edit="showEdit" :is_add="isAdd" :cancel_form="cancelForm"  ></personnelInfor>

  </div>
</template>

<script>
import {getList,enableFalse} from '@/api/user'
import personnelList from './personnelList'
import personnelInfor from "./personnelInfor";
import { isMobile } from '@/utils/common'
export default {
  components: {
    personnelInfor,
    personnelList,
  },
  props:{
    show_personnel: Boolean,
    enterprise_id: Number,
    bus_id: Number,
    ret_index: Function,
  },
  watch:{
    show_personnel(){
      if(this.show_personnel){
        this.name = null
        this.search()
      }
    },
  },
  data() {
    return {
      showStop:false,
      column:{prop: 'enable', order: 'descending'},
      formData:{},
      perId: 0,
      isAdd:true,
      showEdit:false,
      pageNo:1,
      name:'',
      pageSize:10,
      count:1,
      personnel:[],
      selectAll:false,
      drawerWidth:'85%'
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '90%'
    } else {
      this.drawerWidth = '85%'
    }
  },
  methods:{
    //停用
    enableFalse(){
      let ids = []
      for (let i = 0; i < this.personnel.length; i++) {
        if(this.personnel[i].check){
          ids.push(this.personnel[i].id)
        }
      }
      enableFalse({ids:ids}).then(res => {
        if(res.data && res.data.state=="ok"){
          this.$message.success(res.data.msg ? res.data.msg :'保存成功')
        }else{
          this.$message.error(res.data.msg ? res.data.msg :'保存失败')
        }
      })
      this.init();
      this.showStop = false
    },
    quantityCheck() {
      let sum = 0
      for(let i = 0; i < this.personnel.length ; i++){
        if(this.personnel[i].check){
          sum++
        }
      }
      return sum
    },
    //排序方式
    sortthiscolumn (column){
      this.column=column
      this.search();
    },
    //打开新增页面
    insert(){
      this.showEdit = true;
      this.isAdd = true;
    },
    //返回
    cancelForm(){
      this.showEdit = false;
      this.init()
    },
    //打开修改页面
    edit(row){
      this.perId = row.id;
      this.isAdd = false;
      this.showEdit = true;
    },
    //反选
    reversal(){
      for(let i = 0; i < this.personnel.length ; i++){
        this.personnel[i].check = !this.personnel[i].check
      }
      this.setSelectAll()
    },
    //全选
    checkAll(){
      this.personnel.forEach((row, index) => {
        row.check = this.selectAll
      })
    },
    //翻页
    handleCurrentChange(pageNo){
      this.pageNo = pageNo
      this.init()
    },
    //设置列表行数
    handleSizeChange(size){
      this.setPageSizeIndex(size)
      this.search()
    },
    //刷选列表
    init(){
      this.pageSize=this.pageSizeIndex
      let list
      getList({
        orginId:this.bus_id,
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        name:this.name,
        prop:this.column.prop,
        order:this.column.order,
      }).then(res => {
        list = res.data.data.list
        list.forEach((row, index) => {
          row.check = false
        })
        this.count=res.data.data.totalRow
        this.personnel = list
        this.selectAll = false
      })
    },
    //初始化
    search(){
      this.pageNo = 1
      this.init()
    },
    //全选
    setSelectAll(){
      for(let i = 0; i < this.personnel.length ; i++){
        if(this.personnel[i].check){

        }else{
          this.selectAll = false;
          return;
        }
      }
      this.selectAll = true;
    },
  }
}
</script>

<style scoped>
.tools-box{
  float: left;
  margin-left: 1vw;
  margin-top: 1.2vh;
  border-right: 0.1vw solid #c5c5c5;
}
.equipment-card{
  box-shadow: -0px 0px 20px #3e3e3e;
  position: absolute;
  right: 0;
  width: 68%;
  min-width: 400px;
  height: 100%;
  z-index:998;
  overflow-y: auto;
}
.bottom{
  position: absolute;
  width: 98%;
  height: 50px;
  bottom: 0;
  margin-left: calc(1% - 10px);
  box-shadow: 0px -3px 3px #c4c4c4;
}
</style>
<style>
.el-checkbox__label {
  display: inline-block;
  padding-left: 1.2vh;
  line-height: 1.2vh;
  font-size: 1.2vh;
}

</style>