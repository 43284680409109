<template>
  <div class="scroll-container">
    <el-table
        :data="equipments"
        ref="table"
        border
        show-header
        highlight-current-row
        size="mini"
        @sort-change='sortthiscolumn'
        :default-sort = "{prop: 'enable', order: 'descending'}"
    >
      <el-table-column  align="center" width="50" label="">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.check" @change="set_select_all"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column  align="center" width="250" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="bottom-start">
            <el-button size="mini" plain @click="edit(scope.row)">
              <img :src="require('@/icons/svg/edit.svg')" >
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="设备警情" placement="bottom-start">
            <el-button size="mini" plain @click="equipmentLog(scope.row)" :disabled="!scope.row.enable">
              <img :src="scope.row.enable?require('@/icons/svg/alert_log.svg'):require('@/icons/svg/alert_log-disabled.svg')" >
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="历史温湿度（列表）" placement="bottom-start">
            <el-button v-if="scope.row.equipment_model_id == 3" size="mini" plain @click="equipmentList(scope.row)" :disabled="!scope.row.enable">
              <img :src="scope.row.enable?require('@/icons/svg/temp_log.svg'):require('@/icons/svg/temp_log-disabled.svg')" >
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="历史温湿度（图表）" placement="bottom-start">
            <el-button v-if="scope.row.equipment_model_id == 3" size="mini" plain @click="equipmentEcharts(scope.row)" :disabled="!scope.row.enable">
              <img :src="scope.row.enable?require('@/icons/svg/data.svg'):require('@/icons/svg/data-disabled.svg')">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column align="center" label="库房" sortable='custom' prop="library">
        <template slot-scope="{row}">
          <img v-show="LocationLengthOver500(row.longitude,row.longitude_avg,row.latitude,row.latitude_avg)" :src="require('@/icons/svg/location_fill.svg')" />
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.library }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="设备名称" sortable='custom' prop="name">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.name }}</span>
          <img v-if="row.power > 0 && row.power/1000.0 < row.low_power" :src="require('@/icons/svg/low-power.svg')" style="margin-left:10px;" >
        </template>
      </el-table-column>

      <el-table-column align="center" label="状态" sortable='custom' prop="status">
        <template slot-scope="{row}">
          <span :style="row.status==0?'color: #d0d0d0':(row.status==1 ? 'color: #5DC64F' : (row.status==2 ? 'color: #ff0000' : 'color: #ffc43d'))">{{ row.status==0?'离线': (row.status==1 ? '在线': (row.status==2 ? '警情':'故障')) }}</span>
        </template>
      </el-table-column>

      <el-table-column class-name="status-col" align="center" label="电量" sortable='custom' prop="power">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.power/1000.0 }}V</span>
        </template>
      </el-table-column>

      <el-table-column class-name="status-col" align="center" label="信号" sortable='custom' prop="signal">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.signal }}dBm</span>
        </template>
      </el-table-column>

      <el-table-column  align="center" label="下限<温度（℃）<上限">
        <template slot-scope="{row}">
          <template v-if="row.equipment_model_id == 3">
            <span v-if="row.temperature_low < row.temperture && row.temperture < row.temperature_high || row.temperature_low==null && row.temperture==null && row.temperature_high==null" >{{ row.temperature_low }}&nbsp;&lt;&nbsp;{{ row.temperture?row.temperture:'-'  }}&nbsp;&lt;&nbsp;{{ row.temperature_high }}</span>
            <span v-else style="color: red">{{ row.temperature_low }}&nbsp;&lt;&nbsp;{{ row.temperture?row.temperture:'-' }}&nbsp;&lt;&nbsp;{{ row.temperature_high }}</span>
          </template>
          <template v-if="row.equipment_model_id != 3">
            <span>-</span>
          </template>
        </template>
      </el-table-column>

      <el-table-column  align="center" label="下限<湿度（%）<上限">
        <template slot-scope="{row}">
          <template v-if="row.equipment_model_id == 3">
            <span v-if="row.humidity_low < row.humidity && row.humidity < row.humidity_high || row.humidity_low==null && row.humidity==null && row.humidity_high==null" >{{ row.humidity_low }}&nbsp;&lt;&nbsp;{{ row.humidity?row.humidity:'-' }}&nbsp;&lt;&nbsp;{{ row.humidity_high }}</span>
            <span v-else  style="color: red">{{ row.humidity_low }}&nbsp;&lt;&nbsp;{{ row.humidity?row.humidity:'-' }}&nbsp;&lt;&nbsp;{{ row.humidity_high }}</span>
          </template>
          <template v-if="row.equipment_model_id != 3">
            <span>-</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="最新数据时间"  sortable='custom' prop="last_update_time">
        <template slot-scope="scope">
          <span :style="scope.row.enable?'':'color: #d0d0d0'">{{ scope.row.last_update_time }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="接入时间"  sortable='custom' prop="create_time">
        <template slot-scope="scope">
          <span :style="scope.row.enable?'':'color: #d0d0d0'">{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    bus_id:Number,
    edit: Function,
    equipments: Array,
    set_select_all: Function,
    sortthiscolumn: Function,
  },
  data(){
    return {
      checkedList: []
    }
  },
  methods:{
    equipmentLog(row){
      this.$router.push({path:'/log/equalarm/index',query: {eid:row.id,oid:this.bus_id}})
    },
    equipmentList(row){
      this.$router.push({path:'/log/temperature-list/index',query: {eid:row.id,oid:this.bus_id}})
    },
    equipmentEcharts(row){
      this.$router.push({path:'/log/temperature-echarts/index',query: {eid:row.id,oid:this.bus_id}})
    },
    LocationLengthOver500(longitude1, longitude2, latitude1, latitude2){
      let lng1 = longitude1 * (Math.PI/180)
      let lng2 = longitude2 * (Math.PI/180)

      let lat1 = latitude1  * (Math.PI/180)
      let lat2 = latitude2  * (Math.PI/180)

      let a = Math.abs(lng1 - lng2);
      let b = Math.abs(lat1 - lat2);

      let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(b / 2), 2)))
          * 6371393;
      return s > 500
    }
  }
}
</script>

<style scoped>
.scroll-container {
  height: 86vh;
  overflow: auto;
}
</style>