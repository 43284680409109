<template>
  <div class="scroll-container">
    <el-table
        :data="personnel"
        ref="table"
        border
        show-header
        highlight-current-row
        size="mini"
        @sort-change='sortthiscolumn'
        :default-sort = "{prop: 'enable', order: 'descending'}"
    >
      <el-table-column  align="center" width="50" label="">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.check" @change="set_select_all"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column  align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="bottom-start">
            <el-button size="mini" plain @click="edit(scope.row)">
              <img :src="require('@/icons/svg/edit.svg')" >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column align="center" label="姓名" sortable='custom' prop="name">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="电话">
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.mobile_phone }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="是否接收警情">
        <template slot-scope="{row}">
          <span :style="row.receive_alert?'color: #5DC64F':'color: #ff0000'">{{ row.receive_alert ? '是':'否' }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="是否接收公众号警情">
        <template slot-scope="{row}">
          <span :style="row.receive_wechat?'color: #5DC64F':'color: #ff0000'">{{ row.receive_wechat ? '是':'否' }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="是否接听报警电话">
        <template slot-scope="{row}">
          <span :style="row.receive_call?'color: #5DC64F':'color: #ff0000'">{{ row.receive_call ? '是':'否' }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="是否接收报警短信">
        <template slot-scope="{row}">
          <span :style="row.receive_message?'color: #5DC64F':'color: #ff0000'">{{ row.receive_message ? '是':'否' }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    edit: Function,
    personnel: Array,
    set_select_all: Function,
    sortthiscolumn: Function,
  },
  data(){
    return {
      checkedList: []
    }
  },
  methods:{

  }
}
</script>

<style scoped>
.scroll-container {
  height: 86vh;
  overflow: auto;
}
</style>