<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_edit" :before-close="cancel_form">
        <div style="position: absolute;width: 100%;top: 88vh">
          <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 30%"  @click="cancel_form()">取消</el-button>
          <el-button  class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">保存</el-button>
        </div>
        <div style="padding-top:2.2vh;padding-left: 2.4vw;padding-right: 2.2vh;height:88vh;overflow: auto;">
          <div v-if="is_add" slot="header" style="height: 50px;" >
            <span><i class="el-icon-circle-plus-outline"></i> 添加</span>
          </div>
          <div v-else slot="header" style="height: 50px;">
            <span><i class="el-icon-edit"></i> 编辑</span>
          </div>
          <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
            <el-form-item label="名称:" prop="name">
              <el-input placeholder="请输入名称" type="text" v-model="formData.name" ></el-input>
            </el-form-item>
            <el-form-item label="英文名:" prop="englishName">
              <el-input placeholder="请输入英文名" type="text" v-model="formData.englishName" ></el-input>
            </el-form-item>
            <el-form-item label="服务商:" prop="supplierId">
              <el-select v-model="formData.supplierId" placeholder="服务商" size="mini" style="width:200px; padding-right: 10px" clearable @change="changeSupplier">
                <el-option
                    v-for="item in provider_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区域:" required>
              <el-col :span="7">
                <el-form-item prop="areaId1">
                  <el-select v-model="formData.areaId1" placeholder="省" size="mini" style="width:133px; padding-right: 10px"  @change="areaId1Chang(formData.areaId1)">
                    <el-option
                        v-for="item in region_list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <el-form-item prop="areaId2">
                  <el-select v-model="formData.areaId2" placeholder="市" size="mini" style="width:133px; padding-right: 10px"  @change="areaId2Chang(formData.areaId2)">
                    <el-option
                        v-for="item in city"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item prop="areaId3">
                  <el-select v-model="formData.areaId3" placeholder="区" size="mini" style="width:133px; padding-right: 10px"  >
                    <el-option
                        v-for="item in area"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="详细地址:" prop="address">
              <el-input placeholder="请输入详细地址" type="text" v-model="formData.address" ></el-input>
            </el-form-item>
            <el-form-item label="联系人:" prop="firstContact">
              <el-input placeholder="请输入联系人" type="text" v-model="formData.firstContact" ></el-input>
            </el-form-item>
            <el-form-item label="序列号:" prop="serialNumber">
              <el-input placeholder="请输入序列号" type="text" v-model="formData.serialNumber" ></el-input>
            </el-form-item>
            <el-form-item label="有效期:" prop="lisenstExpireData">
              <el-date-picker
                  v-model="formData.lisenstExpireData"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="启用停用:" >
              <el-checkbox class="myRedCheckBox" v-model="formData.enable"  :label="(formData.enable?'已启用':'已禁用')"></el-checkbox>
            </el-form-item>
            <el-form-item v-if="!is_add" label="二维码:">
              <img :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+formData.qrcode" style="margin-left: 10px;margin-right: 10px;width: 20vh">
            </el-form-item>
          </el-form>

        </div>

      </el-drawer>
    </transition>

  </div>


</template>

<script>
import { getRegion} from '@/api/area'
import { add,update,getOneById,getSupById} from '@/api/orgin'
import { isMobile } from '@/utils/common'
export default {
  props:{
    is_add: Boolean,
    cancel_form: Function,
    show_edit: Boolean,
    bus_id: Number,
    provider_list: Array,
    region_list: Array,
  },
  watch:{
    show_edit(){
      if(this.show_edit){
        if(this.is_add){
          this.$refs.ruleForm.resetFields();
          this.formData.qrcode= ''
        }else{
          getOneById({id:this.bus_id}).then(res => {
            this.$refs.ruleForm.resetFields();
            this.formData.name = res.data.data.name;
            this.formData.address = res.data.data.address;
            this.formData.areaId1 = res.data.data.area_id1;
            this.areaId1Chang(this.formData.areaId1);
            this.formData.areaId2 = res.data.data.area_id2;
            this.areaId2Chang(this.formData.areaId2);
            this.formData.areaId3 = res.data.data.area_id3;
            this.formData.enable = res.data.data.enable;
            this.formData.firstContact = res.data.data.first_contact;
            this.formData.lisenstExpireData = new Date(res.data.data.lisense_expire_data);
            this.formData.serialNumber = res.data.data.serial_number;
            this.formData.supplierId = res.data.data.supplier_id;
            this.formData.qrcode = res.data.data.qrcode;
            this.formData.englishName = res.data.data.english_name
          })
        }
      }
    }
  },
  data(){
    return {
      city: [
      ],
      area: [
      ],
      formData: {
        englishName:'',//英文名
        name:'',//名称
        supplierId:null,//服务商
        areaId1:null,//区域  省
        areaId2:null,//区域  市
        areaId3:null,//区域  区
        address:'',//详细地址
        firstContact:'',//联系人电话
        serialNumber:'',//序列号
        lisenstExpireData:'',//有效期
        enable:false,
        qrcode:'',//二维码
      },
      rules:{
        name:[
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        supplierId:[
          { required: true, message:'请选择服务商', trigger: 'change' }
        ],
        areaId1:[
          { required: true, message: '省不能为空', trigger: 'change' },
        ],
        areaId2:[
          { required: true, message: '市不能为空', trigger: 'change' },
        ],
        areaId3:[
          { required: true, message: '区不能为空', trigger: 'change' },
        ],
        address:[
          { required: true, message: '详细地址不能为空', trigger: 'blur' },
        ],
        firstContact:[
          { required: true,  message: '联系人不能为空', trigger: 'blur' },
        ],
        serialNumber:[
          { required: true, message: '序列号不能为空', trigger: 'blur' },
        ],
        lisenstExpireData:[
          { required: true, message: '有效期不能为空', trigger: 'change' ,type: 'date'},
        ],
        englishName:[
          { required: true, message: '英文名称不能为空', trigger: 'blur'}
        ]
      },
      drawerWidth:'35%'
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '35%'
    }
  },
  methods: {
    changeSupplier(val){
      if(val != null && val != ""){
        getSupById({id:val}).then(res => {
          if(res.data.data.area != this.formData.areaId1){
            this.formData.areaId1 = res.data.data.area;
            this.areaId1Chang(this.formData.areaId1);
          }
        })
      }
    },
    //联动查询省级信息
    areaId1Chang(pid){
      this.area = []
      this.formData.areaId2 = null
      this.formData.areaId3 = null
      getRegion({pid:pid}).then(res => {
        this.city = res.data.data
      })
    },
    //联动查询市级信息
    areaId2Chang(pid){
      this.formData.areaId3 = null
      getRegion({pid:pid}).then(res => {
        this.area = res.data.data
      })
    },
    //提交
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let lisenstExpireData = this.formData.lisenstExpireData
          this.formData.lisenstExpireData = new Date(+new Date(lisenstExpireData) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
          if(this.is_add){
            add(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }else {
            this.formData.id = this.bus_id
            update(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }
          this.formData.lisenstExpireData = lisenstExpireData
        }
      })

    },
  },
}
</script>

<style scoped>

</style>
<style>
.myRedCheckBox .el-checkbox__input+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0f9f00;
}

.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0f9f00;
}
.myRedCheckBox .el-checkbox__input .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #0f9f00;
}
</style>