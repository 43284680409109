<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_remove" :before-close="cancel_remove">
        <div style="padding-top:20px;padding-left: 40px;padding-right: 40px">
        <div slot="header" style="height: 50px" >
          <span>迁移设备</span>
        </div>

        <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
          <el-form-item label="服务商:" prop="providerNew">
            <el-select v-model="formData.providerNew" placeholder="服务商" size="mini" style="width:200px; padding-right: 10px" @change="selectChange">
              <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="终端用户:" prop="orgin">
            <el-select v-model="formData.orgin" placeholder="终端用户" size="mini" style="width:200px; padding-right: 10px" clearable >
              <el-option
                  v-for="item in orginList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="隐藏历史数据:" prop="remove" >
              <el-checkbox class="myRedCheckBox" v-model="formData.remove"  :label="(formData.remove?'已隐藏':'未隐藏')"></el-checkbox>
          </el-form-item>
          <div style="height: 482px"></div>
          <div style="position: absolute;width: 100%;top: 88vh">
            <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 20%"  @click="cancel_remove()">取消</el-button>
            <el-button class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">确定</el-button>
          </div>
        </el-form>
      </div>
      </el-drawer>
    </transition>
  </div>
</template>

<script>
import { isMobile } from '@/utils/common'
import { getProviders, getOrginListBySupplierId } from "@/api/datareport";
import { removeEquipment } from "@/api/equipment"
export default {
  props:{
    show_remove: Boolean,
    cancel_remove: Function,
    cancel_form: Function,
    orgin_list: Array,
    equipment_list: Array,
  },
  data(){
    return {
      formData:{
        providerNew:null,
        orgin:null,
        remove:true
      },
      rules:{
        providerNew:[
          { required: true, message: '服务商不能为空', trigger: 'change' },
        ],
        orgin:[
          { required: true, message: '终端用户不能为空', trigger: 'change' },
        ],
      },
      drawerWidth:'35%',
      providerList:[],
      orginList:[],
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '35%'
    }
    this.init()
  },
  methods: {
    init(){
      getProviders().then((res) => {
        if (res.data.state == "ok") {
          this.providerList = res.data.data;
          this.getOrginList();
        }
      });
    },
    getOrginList() {
      let params = {
        supplierId: this.formData.providerNew,
      };
      if (this.formData.providerNew == null) {
        return
      }
      getOrginListBySupplierId(params).then((res) => {
        if (res.data.state == "ok") {
          this.orginList = res.data.data;
        }
      });
    },
    selectChange(){
      this.formData.orgin = null
      this.getOrginList()
    },
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let ids = []
          for (let i = 0; i < this.equipment_list.length; i++) {
            if(this.equipment_list[i].check){
              ids.push(this.equipment_list[i].id)
            }
          }
          this.formData.ids = ids
          removeEquipment(this.formData).then(res => {
            if(res.data && res.data.state=="ok"){
              this.$message.success(res.data.msg ? res.data.msg :'迁移成功')
              this.cancel_form();
            }else{
              this.$message.error(res.data.msg ? res.data.msg :'迁移失败')
            }
          })
        }
      })
    },
  },
  watch:{
    show_remove(){
      if (this.show_remove) {
        this.$refs['ruleForm'].clearValidate();
      } {
        let form = {providerNew:null,orgin:null,remove:true}
        this.formData = Object.assign({},form)
      }
    }
  }
}
</script>

<style scoped>
.edit-card{
  box-shadow: -0px 0px 20px #3e3e3e;
  position: absolute;
  right: 0;
  width: 35%;
  min-width: 400px;
  height: 100%;
  z-index:998;
  overflow-y: auto;
}

.myRedCheckBox .el-checkbox__input+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0f9f00;
}

.myRedCheckBox .el-checkbox__input .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #0f9f00;
}

</style>