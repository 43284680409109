<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_edit" :before-close="cancel_form">
        <div style="position: absolute;width: 100%;top: 88vh">
          <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 30%"  @click="cancel_form()">取消</el-button>
          <el-button v-if="!is_add" class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">保存</el-button>
        </div>
        <div style="padding-top:2.2vh;padding-left: 2.4vw;padding-right: 2.2vh;height:88vh;overflow: auto;">
          <div v-if="is_add" slot="header" style="height: 50px">
            <span><i class="el-icon-circle-plus-outline"></i> 添加</span>
          </div>
          <div v-else slot="header" style="height: 50px">
            <span><i class="el-icon-edit"></i> 编辑</span>
          </div>



          <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
            <el-form-item  v-if="!is_add" label="姓名:" prop="name">
              <el-input placeholder="请输入姓名" type="text" v-model="formData.name" ></el-input>
            </el-form-item>
            <el-form-item v-show="!is_add && mobileProp" key="1" label="电话:" prop="mobile_phone">
              <el-input placeholder="请输入电话" type="text" v-model="formData.mobile_phone" ></el-input>
            </el-form-item>
            <el-form-item  v-show="!is_add && !mobileProp" key="2" label="电话:">
              <el-input placeholder="请输入电话" type="text" v-model="formData.mobile_phone" ></el-input>
            </el-form-item>
            <el-form-item  v-if="!is_add" label="接受警情:" >
              <el-checkbox  v-model="formData.receive_alert"  :label="(formData.receive_alert?'  接收':'不接收')"></el-checkbox>
            </el-form-item>
            <el-form-item  v-if="!is_add" label="启用/停用:">
              <el-checkbox  v-model="formData.receive_call">电话</el-checkbox>
              <el-checkbox  v-model="formData.receive_message">短信</el-checkbox>
              <el-checkbox  v-model="formData.receive_wechat">微信</el-checkbox>
            </el-form-item>
            <el-form-item  v-if="!is_add"  label="接受警情时间:" required>
              <el-col :span="7">
                <el-form-item prop="receive_time_start">
                  <el-time-picker
                      style="width: 140px"
                      v-model="formData.receive_time_start"
                      :picker-options="{
                        selectableRange: '00:00:00 - '+(formData.receive_time_end == null?'23:59:59':formData.receive_time_end)
                      }"
                      placeholder="任意时间点">
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="1" style="text-align: center">—</el-col>
              <el-col :span="7">
                <el-form-item prop="receive_time_end">
                  <el-time-picker
                      style="width: 140px"
                      v-model="formData.receive_time_end"
                      :picker-options="{
                        selectableRange: (formData.receive_time_start == null?'00:00:00':formData.receive_time_start)+' - 23:59:59'
                      }"
                      placeholder="任意时间点">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item v-if="!is_add"  label="启用停用:" >
              <el-checkbox class="myRedCheckBox" v-model="formData.enable"  :label="(formData.enable?'已启用':'已禁用')"></el-checkbox>
            </el-form-item>

            <el-form-item v-if="is_add" label="二维码:">
              <img :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+qrcode" style="margin-left: -75px;margin-right: 1vw;width: 27vw">
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </transition>

  </div>


</template>

<script>
import {add,update,getOneById,getQrcode} from '@/api/user'
import {Encrypt} from '@/utils/secert'
import { isMobile } from '@/utils/common'
export default {
  props:{
    per_id: Number,
    is_add: Boolean,
    cancel_form: Function,
    show_edit: Boolean,
    bus_id: Number,
    storage_list: Array,
  },
  watch:{
    show_edit(){
      if(this.show_edit){
        if(this.is_add){
          getQrcode({orginId:this.bus_id}).then(res => {
            this.qrcode = res.data.qrcode
          })
         }else{
          getOneById({id:this.per_id}).then(res => {
            this.$nextTick(() => {
              this.$refs['ruleForm'].clearValidate();
              this.formData.name=res.data.data.name;//姓名
              this.formData.mobile_phone=res.data.data.mobile_phone;//电话
              this.formData.receive_alert=res.data.data.receive_alert;//接受警情
              this.formData.receive_call=res.data.data.receive_call;//电话通知
              this.formData.receive_message=res.data.data.receive_message;//短信通知
              this.formData.receive_wechat=res.data.data.receive_wechat;//微信通知
              this.formData.receive_time_start=new Date("2021/7/2 "+res.data.data.receive_time_start)
              this.formData.receive_time_end=new Date("2021/7/2 "+res.data.data.receive_time_end)
              this.formData.enable=res.data.data.enable
            })
          })
        }
      }
    },
    formData:{
      handler(val) {
        if(this.show_edit){
          if (this.formData.receive_call || this.formData.receive_message) {
            this.mobileProp = true
          } else {
            this.mobileProp = false
            this.$refs['ruleForm'].clearValidate();
          }
        } else {
          this.$refs['ruleForm'].clearValidate();
        }
      },
      deep:true,
    }
  },
  data(){
    var checkPhone = (rule,value,callback) =>{
      let reg = /^1[34578]\d{9}$/
        if (this.mobileProp) {
          if (value === null || value.trim() === '') {
            callback(new Error('手机号不能为空'))
          } else {
            if (reg.test(value)) {
              callback()
            } else {
              return callback(new Error('手机号格式不正确'))
            }
          }
        } else {
          callback()
        }
    }
    return {
      qrcode:null,
      formData: {
        enable:false,
        id:null,
        name:'',//姓名
        mobile_phone:null,//电话
        receive_alert:null,//接受警情
        receive_call:false,//电话通知
        receive_message:false,//短信通知
        receive_wechat:false,//微信通知
        receive_time_start:null,
        receive_time_end:null,
      },
      rules:{
        name:[
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        username:[
          { required: true, message:'账号不能为空', trigger: 'blur' }
        ],
        password:[
          { required: true, message:'密码不能为空', trigger: 'blur' }
        ],
        mobile_phone:[
          { required: true, validator:checkPhone, trigger: 'blur' },
        ],
        receive_time_start:[
          { required: true, message:'开始时间不能为空', trigger: 'blur' }
        ],
        receive_time_end:[
          { required: true, message:'截至时间不能为空', trigger: 'blur' }
        ],
      },
      drawerWidth:'35%',
      mobileProp:false
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '35%'
    }
  },
  methods: {
    //提交
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.formData.orginId = this.bus_id
          var start = this.formData.receive_time_start.toJSON();
          this.formData.receive_time_start_utc = new Date(+new Date(start) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
          var end = this.formData.receive_time_end.toJSON();
          this.formData.receive_time_end_utc = new Date(+new Date(end) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
          var password = this.formData.password
          if(this.formData.password){
            this.formData.password = Encrypt(this.formData.password)
          }
          if(this.is_add){
            add(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }else {
            this.formData.id = this.per_id
            update(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }
          this.formData.password = password
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
<style>
.myRedCheckBox .el-checkbox__input+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0f9f00;
}

.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0f9f00;
}
.myRedCheckBox .el-checkbox__input .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #0f9f00;
}
</style>