<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_supplier" :before-close="cancel_form">
        <div style="padding-top:20px;padding-left: 40px;padding-right: 40px">
        <div slot="header" style="height: 50px" >
          <span>转服务商</span>
        </div>

        <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
<!--          <el-form-item label="原服务商:" prop="providerOld">-->
<!--            <el-input disabled type="text" v-model="formData.providerOld" ></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="新服务商:" prop="providerNew">
            <el-select v-model="formData.providerNew" placeholder="服务商" size="mini" style="width:200px; padding-right: 10px" clearable >
              <el-option
                  v-for="item in provider_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="height: 482px"></div>
          <div style="position: absolute;width: 100%;top: 88vh">
            <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 20%"  @click="cancel_form()">取消</el-button>
            <el-button class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">确定</el-button>
          </div>
        </el-form>
      </div>
      </el-drawer>
    </transition>
  </div>
</template>

<script>
import { replace } from '@/api/orgin'
import { isMobile } from '@/utils/common'
export default {
  props:{
    show_supplier: Boolean,
    bus_id: Number,
    cancel_form: Function,
    provider_list: Array,
    enterprise_list: Array,
  },
  data(){
    return {
      formData:{
        providerNew:null
      },
      rules:{
        providerNew:[
          { required: true, message: '服务商不能为空', trigger: 'change' },
        ],
      },
      drawerWidth:'35%'
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '35%'
    }
  },
  methods: {
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let ids = []
          for (let i = 0; i < this.enterprise_list.length; i++) {
            if(this.enterprise_list[i].check){
              ids.push(this.enterprise_list[i].id)
            }
          }
          this.formData.ids = ids
          replace(this.formData).then(res => {
            if(res.data && res.data.state=="ok"){
              this.$message.success(res.data.msg ? res.data.msg :'保存成功')
              this.cancel_form();
            }else{
              this.$message.error(res.data.msg ? res.data.msg :'保存失败')
            }
          })
        }
      })
    },
  },
  watch:{
    show_supplier(){
      if (this.show_supplier) {
        this.$refs['ruleForm'].clearValidate()
      } else {
        this.formData.providerNew = null
      }
    }
  }
}
</script>

<style scoped>
.edit-card{
  box-shadow: -0px 0px 20px #3e3e3e;
  position: absolute;
  right: 0;
  width: 35%;
  min-width: 400px;
  height: 100%;
  z-index:998;
  overflow-y: auto;
}
</style>