<template>
  <div>

    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_equipment" :before-close="ret_index">
        <div style="padding:10px">
        <div>
          <div style="float: left;width: 40%;margin-left: 1%;margin-top: 1vh;color: #808080">
            <span>设备列表（{{ equipments.length }}条/共{{ count }}条）</span>
          </div>
          <div class="tools-bar" :style="bus_id == 1 ? 'float: right;padding: 0.4vw 0.8vh;height: 2.5vh;':'float: right;padding: 0.4vw 0.8vh;height: 2.5vh;'">
            
            <el-select v-model="type" placeholder="设备类型" size="mini" style="width:11vw; padding-right: 0.5vw" clearable @change="search">
              <el-option
                  v-for="item in equipmentTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            
            <el-select v-model="storage" placeholder="库房" size="mini" style="width:11vw; padding-right: 0.5vw" clearable @change="search">
              <el-option
                  v-for="item in storageList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
              >
              </el-option>
            </el-select>
            <el-input style="width:11vw; padding-right: 0.5vw;" size="mini" type="text" v-model="name" clearable placeholder="搜索" @keyup.enter.native="search" @clear="search"></el-input>
            <el-button style="" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          </div>
        </div>
        <div  style="margin-top: 6.2vh;clear: both;margin-left: 1%;width: 98%;height: 86.5vh;">
          <equipmentList :bus_id="bus_id" :sortthiscolumn="sortthiscolumn" :edit="edit" :equipments="equipments" :set_select_all="setSelectAll"></equipmentList>
        </div>
        <div class="bottom">
          <div class="tools-box">
            <el-checkbox  size="small" v-model="selectAll" @change="checkAll()">全选本页</el-checkbox>
            <!-- <el-button plain style="margin-left: 1.2vw;width: 5.3vw;height: 3.8vh;color: #808080;" @click="reversal">选中其他</el-button> -->
            <el-popover style="margin-left: 0.8vw;margin-right: 0.5vw;"
                placement="top"
                width="190"
                v-model="showBagen">
              <p>确定启用当前勾选的{{ quantityCheck() }}个设备？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" type="text" @click="showBagen = false">取消</el-button>
                <el-button type="primary" size="small" @click="enableTrue">确定</el-button>
              </div>
              <el-button slot="reference" size="small">启用</el-button>
            </el-popover>
            <el-popover
                placement="top"
                width="190"
                v-model="showStop">
              <p>确定停用当前勾选的{{ quantityCheck() }}个设备？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" type="text" @click="showStop = false">取消</el-button>
                <el-button type="primary" size="small" @click="enableFalse">确定</el-button>
              </div>
              <el-button slot="reference" size="small">停用</el-button>
            </el-popover>

          </div>
          <div style="float: left;margin-top: 1.3vh;height: 3.8vh;margin-right: 0.5vw;">
            <el-button size="small" type="primary"  @click="insert">新增</el-button>
          </div>
          <div style="float: left;margin-top: 1.3vh;height: 3.8vh;margin-right: 0.5vw;">
            <el-button size="small" type="primary"  @click="remove">迁移</el-button>
          </div>
          <div v-if="bus_id == 1" style="float: left;margin-top: 1.3vh;height: 3.8vh;margin-right: 0.5vw;">
            <el-upload
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  :show-file-list="false"
                  class="upload-demo"
                  action=""
                  :before-upload="beforeAvatarUpload"
                  with-credentials="ture"
                  >
                <el-button v-if="bus_id == 1" size="small" icon="el-icon-upload" type="primary">导入</el-button>
              </el-upload>
          </div>
          <div style="float: left;margin-top: 1.3vh;height: 3.8vh;margin-right: 0.5vw;">
            <el-popover
              placement="top"
              width="190"
              v-model="showExport">
              <p>确定导出当前勾选的{{ quantityCheck() }}个设备？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" type="text" @click="showExport = false">取消</el-button>
                <el-button type="primary" size="small" @click="exportForm">确定</el-button>
              </div>
              <el-button slot="reference" size="small" type="primary" icon="el-icon-download">导出</el-button>
            </el-popover>
          </div>
          <div style="float: left;margin-top: 1.3vh;height: 3.8vh;">
            <el-button class="cancel" size="small"       @click="ret_index()">返回</el-button>
          </div>

          <div style="float: right;margin-right: 1vw;margin-top: 1.3vh;">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="4"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="count">
            </el-pagination>
          </div>
        </div>
      </div>
      </el-drawer>
    </transition>
    <equipmentInfor :storage_list="storageList" :bus_id="bus_id" :equ_id="equId" :form_data="formData" :show_edit="showEdit" :is_add="isAdd" :cancel_form="cancelForm"  ></equipmentInfor>
    <removeEquipment :show_remove="showRemove" :equipment_list="equipments" :cancel_form="cancelForm" :cancel_remove="cancelRemove"></removeEquipment>
  </div>
</template>

<script>
import store from '@/store'
import equipmentList from './equipmentList'
import equipmentInfor from "./equipmentInfor";
import removeEquipment from "./removeEquipment";
import {getList,getStorageList,enableTrue,enableFalse,exportForm} from '@/api/equipment'
import axios from "axios";
import { isMobile } from '@/utils/common'
export default {
  components: {
    equipmentInfor,
    equipmentList,
    removeEquipment,
  },
  props:{
    show_equipment: Boolean,
    enterprise_id: Number,
    bus_id: Number,
    ret_index: Function,
  },
  watch:{
    show_equipment(){
      if(this.show_equipment){
        getStorageList({
          orginId:this.bus_id
        }).then(res => {
          this.storageList = res.data.data
        })
        this.pageNo=1
        this.name=null
        this.storage=null
        this.type=null
        this.search()
      }
    },
  },
  data() {
    return {
      store:store,
      url:'http://localhost:11612/admin/equipment/importForm',
      showStop:false,
      showBagen:false,
      showExport:false,
      column:{prop: 'enable', order: 'descending'},
      formData:{},
      equId: 0,
      isAdd:true,
      showEdit:false,
      pageNo:1,
      name:'',
      pageSize:10,
      count:1,
      equipments:[],
      selectAll:false,
      storageList:[
      ],
      storage:null,
      drawerWidth:'85%',
      equipmentTypes:[
        {id:1,name:'烟感'},
        {id:2,name:'浸水'},
        {id:3,name:"温湿度"}
      ],
      type:null,
      showRemove:false
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '90%'
    } else {
      this.drawerWidth = '85%'
    }
  },
  methods:{
    //迁移设备
    remove(){
      let ids = []
      for (let i = 0; i < this.equipments.length; i++) {
        if(this.equipments[i].check){
          ids.push(this.equipments[i].id)
        }
      }
      if(ids.length>0){
        this.showRemove=true;
      }else{
        this.$message.error('请先选中设备！')
      }
    },
    //提交文件
    beforeAvatarUpload(file){
      const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        this.$message.error('只能导入excel!');
      }else {
        if(file.name == ""){
          this.$message.warning('请选择要上传的文件！')
          return false
        }
        let fileFormData = new FormData();
        let data = file
        fileFormData.append('file', data, file.name); //  注意'file'要和后端统一
        let headers = {
          'Content-Type': 'multipart/form-data' // 设置请求头
        }
        axios.post('/api/equipment/importForm?token='+store.state.k, fileFormData, headers).then(res=>{
          this.$message.success(res.data.msg)
          this.search()
        })
      }
      return false;
    },
    //导入失败处理
    error(res, file){
      this.$message.error('导入失败')
    },
    //导入成功处理
    success(res, file){
      this.$message.success('导入成功')
    },
    /**
     * @description 导出设备列表
     * @author xiaoying
     * @date 2021-11-29
     */
    exportForm(){
      let ids = ''
      for (let i = 0; i < this.equipments.length; i++) {
        if(this.equipments[i].check){
          ids = ids + this.equipments[i].id + ','
        }
      }
      ids = ids.substr(0,ids.length-1)
      if (ids.length==0) {
        this.$message.warning('请先勾选需要导出的数据')
        return
      }
      exportForm({ids:ids}).then(res => {
        if(res.data && res.data.state=="ok"){
          const url = '/api/equipment/downloadExcelFile?file=' + res.data.file
          location.href = url
        }else{
          this.$message.error(res.data.msg ? res.data.msg :'导出失败')
        }
      })
      this.showExport = false
    },
    quantityCheck() {
      let sum = 0
      for(let i = 0; i < this.equipments.length ; i++){
        if(this.equipments[i].check){
          sum++
        }
      }
      return sum
    },
    //启用
    enableTrue(){
      let ids = []
      for (let i = 0; i < this.equipments.length; i++) {
        if(this.equipments[i].check){
          ids.push(this.equipments[i].id)
        }
      }
      enableTrue({ids:ids}).then(res => {
        if(res.data && res.data.state=="ok"){
          this.$message.success(res.data.msg ? res.data.msg :'保存成功')
          this.init();
        }else{
          this.$message.error(res.data.msg ? res.data.msg :'保存失败')
        }
      })
      this.showBagen = false
    },
    //停用
    enableFalse(){
      let ids = []
      for (let i = 0; i < this.equipments.length; i++) {
        if(this.equipments[i].check){
          ids.push(this.equipments[i].id)
        }
      }
      enableFalse({ids:ids}).then(res => {
        if(res.data && res.data.state=="ok"){
          this.$message.success(res.data.msg ? res.data.msg :'保存成功')
          this.init();
        }else{
          this.$message.error(res.data.msg ? res.data.msg :'保存失败')
        }
      })
      this.showStop = false
    },
    //设置排序方式
    sortthiscolumn (column){
      this.column=column
      this.search();
    },
    //打开新增页
    insert(){
      this.showEdit = true;
      this.isAdd = true;
      this.showRemove = false
    },
    //返回
    cancelForm(){
      this.showEdit = false;
      this.showRemove = false
      this.init()
    },
    //迁移返回操作
    cancelRemove(){
      this.showEdit = false;
      this.isAdd = false
      this.showRemove = false
    },
    //打开修改页
    edit(row){
      this.equId = row.id;
      this.isAdd = false;
      this.showEdit = true;
      this.showRemove = false
    },
    //反选
    reversal(){
      for(let i = 0; i < this.equipments.length ; i++){
        this.equipments[i].check = !this.equipments[i].check
      }
      this.setSelectAll()
    },
    //全选
    checkAll(){
      this.equipments.forEach((row, index) => {
        row.check = this.selectAll
      })
    },
    //翻页
    handleCurrentChange(pageNo){
      this.pageNo = pageNo
      this.init()
    },
    //设置列表显示行数
    handleSizeChange(size){
      this.setPageSizeIndex(size)
      this.search()
    },
    //列表查询
    init(){
      this.pageSize=this.pageSizeIndex
      var list
      getList({
        orginId:this.bus_id,
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        name:this.name,
        storage:this.storage,
        prop:this.column.prop,
        order:this.column.order,
        type:this.type
      }).then(res => {
        list = res.data.data.list
        list.forEach((row, index) => {
          row.check = false
        })
        this.count=res.data.data.totalRow
        this.equipments = list
        this.selectAll = false
      })

    },
    //初始化列表
    search(){
      this.pageNo = 1
      this.init()
    },
    //设置全选按钮状态
    setSelectAll(){
      for(let i = 0; i < this.equipments.length ; i++){
        if(this.equipments[i].check){

        }else{
          this.selectAll = false;
          return;
        }
      }
      this.selectAll = true;
    },
  }
}
</script>

<style scoped>
/*.tools-bar .el-button {*/
/*  display: inline-block;*/
/*  line-height: 1;*/
/*  white-space: nowrap;*/
/*  cursor: pointer;*/
/*  background: #1890ff;*/
/*  border: 0.1vh solid #1890ff;*/
/*  border-color: #1890ff;*/
/*  color: #ffffff;*/
/*  -webkit-appearance: none;*/
/*  text-align: center;*/
/*  box-sizing: border-box;*/
/*  outline: none;*/
/*  margin: 0;*/
/*  transition: .1s;*/
/*  font-weight: 400;*/
/*  -moz-user-select: none;*/
/*  -webkit-user-select: none;*/
/*  -ms-user-select: none;*/
/*  padding: 0.8vh 1.0vw;*/
/*  font-size: 1.2vh;*/
/*  border-radius: 0.2vw;*/
/*}*/
.transition-box {
  position: absolute;
  margin-left: 250px;
  margin-top:-180px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #aeaeae;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.bottom{
  position: absolute;
  width: 98%;
  height: 50px;
  bottom: 0;
  margin-left: calc(1% - 10px);
  box-shadow: 0px -3px 3px #c4c4c4;
}
.equipment-card{
  box-shadow: -0px 0px 20px #3e3e3e;
  position: absolute;
  right: 0;
  width: 68%;
  min-width: 400px;
  height: 100%;
  z-index:998;
  overflow-y: auto;
}
.tools-box{
  float: left;
  margin-left: 1vw;
  margin-top: 1.2vh;
  padding-right: 0.5vw;
  margin-right: 0.5vw;
  border-right: 0.1vw solid #c5c5c5;
}
.el-checkbox__label {
  display: inline-block;
  padding-left: 1.2vh;
  line-height: 1.2vh;
  font-size: 1.2vh;
}

</style>